<template>
  <div class="body">
    <el-alert type="warning" show-icon title="提示：当前设置更改后，需点击顶部右上角 → “保存发布”，保存后才可生效。"></el-alert>
    <br />
    <div class="box">
      <el-row :gutter="20">
        <el-col :span="7">
          <div class="grid-content pic">
            <el-carousel height="800px">
              <el-carousel-item v-for="item in 4" :key="item">
                <img src="@/assets/image/ghost.jpg" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </el-col>
        <el-col :span="14">
          <div class="grid-content set">
            <el-tabs v-model="activeName" type="card">
              <el-tab-pane label="内容设置" name="first">
                <el-form ref="form" :model="form" label-width="160px" size="mini" id="selectForm">
                  <el-row>
                    <el-form-item label="显示设置">
                      <el-radio-group v-model="radio">
                        <el-radio label="2">首次打开商城时</el-radio>
                        <el-radio label="1">按周期显示</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="自动确认收货时间">
                      <el-col :span="1">
                        <span>每</span>
                      </el-col>
                      <el-col :span="4">
                        <el-input placeholder="0" v-model="input">
                          <template slot="append">天</template>
                        </el-input>
                      </el-col>
                      <el-col :span="4">
                        <span style="margin-left: 20px">显示一次</span>
                      </el-col>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="图片设置">
                      <span style="color: #cccccc">最多添加五张开屏广告图，建议图片尺寸：1080px*1920px</span>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item>
                      <div class="add">
                        <div class="add-pic">
                          <el-upload>
                            <i class="el-icon-plus"></i>
                          </el-upload>
                          <span>添加图片</span>
                        </div>
                      </div>
                    </el-form-item>
                  </el-row>
                </el-form>
              </el-tab-pane>
              <el-tab-pane label="样式设置" name="second">
                <div class="top">
                  <span>首次打开商城时，以全屏的方式展示广告图</span>
                </div>
                <div class="pattern">
                  <span>指示器样式</span>
                  <div class="list mt20">
                    <el-radio-group v-model="radio1" size="small">
                      <el-radio label="1" border>圆角</el-radio>
                      <el-radio label="2" border>原型</el-radio>
                      <el-radio label="3" border>数字</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div class="position">
                  <span>指示器样式</span>
                  <div class="list mt20">
                    <el-radio-group v-model="radio2" size="small">
                      <el-radio label="1" border>居左</el-radio>
                      <el-radio label="2" border>居中</el-radio>
                      <el-radio label="3" border>居右</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div class="color-set">
                  <span>颜色设置</span>
                  <div class="mt20">
                    <div class="block flex col-center">
                      <span class="demonstration">选中颜色</span>
                      <el-color-picker v-model="color1"></el-color-picker>
                      <span style="margin-left: 40px; color: #000080">重置</span>
                    </div>
                  </div>
                </div>
                <div class="browse">
                  <span>浏览方式设置</span>
                  <div class="allow mt20">
                    <span>允许跳过</span>
                    <el-switch v-model="value" active-color="#409eff" inactive-color="#dcdfe6"></el-switch>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      radio: '1',
      radio1: '1',
      radio2: '1',
      input: '',
      imageUrl: '',
      value: true,
      color1: '#409EFF'
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
  height: 100%;
  padding: 15px;
}
.pic img {
  width: 100%;
  height: 100%;
}
.set {
  height: 800px;
}

.add {
  width: 300px;
  height: 500px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  background: #fff;
  padding: 30px;
  .add-pic {
    width: 240px;
    height: 440px;
    text-align: center;
    background-color: #fafafa;
    color: #b8b7b8;
    padding-top: 170px;
    border: 1px dashed #a0a4a4;
    .el-icon-plus {
      font-size: 40px;
      font-weight: bold;
    }
    > span {
      font-size: 18px;
      color: #b8b7b8;
    }
  }
}

.top {
  font-size: 14px;
  margin-bottom: 10px;
  color: #cccccc;
}

.pattern,
.position {
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 30px;
  .list {
    margin: 10px 0;
  }
}

.color-set,
.browse {
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 30px;
  .demonstration {
    line-height: 30px;
  }
}
.allow {
  margin-top: 10px;
  margin-bottom: 10px;
}
.grid-content {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  background: #fff;
  padding: 15px;
  min-height: 100px;
}
</style>
